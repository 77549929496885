import { createStore } from "zustand/vanilla"
import { persist } from "zustand/middleware"
import { openDB } from "idb"

const initialState = {
  roleChannelCollaborator: null,
  user: null,
  channelUser: null,
  appToken: null,
  allowedActions: [],
  profiles: [],
  switchedChannelUser: false,
  featureFlags: {},
}

const STORE_KEY = "auth-store"

export const createIndexedDBAuthStorage = (namedb) => {
  const dbPromise = openDB(namedb, 1, {
    upgrade(db) {
      db.createObjectStore(STORE_KEY)
    },
  })

  return {
    close: async () => {
      return (await dbPromise).close()
    },
    keys: async () => {
      return (await dbPromise).getAllKeys(STORE_KEY);
    },
    clear: async () => {
      return (await dbPromise).clear(STORE_KEY)
    },
    getItem: async (name) => {
      return (await dbPromise).get(STORE_KEY, name)
    },
    setItem: async (name, value) => {
      return (await dbPromise).put(STORE_KEY, value, name)
    },
    removeItem: async (name) => {
      return (await dbPromise).delete(STORE_KEY, name)
    }
  }
}


export const authStore = createStore(
  persist(
    (set, _get) => ({
      ...initialState,
      isLoading: true,
      login: (newState) => {
        set({
          ...newState,
          switchedChannelUser: newState.switchedChannelUser || false,
          allowedActions: newState.allowedActions || [],
          featureFlags: newState.featureFlags || {},
          profiles: newState.channelUsers || [newState.user],
          channelUser: newState.channelUser || null,
          roleChannelCollaborator: newState.user?.roleChannelCollaborator,
          isLoading: false
        })
      },
      logout: () => set({ ...initialState, isLoading: false }),
      modifyUser: (user) => set({ user }),
      modifyProfiles: (newChannelUser) =>
        set((state) => ({ profiles: [...state.profiles, newChannelUser] })),
      setLoading: (isLoading) => set({ isLoading }),
    }),
    {
      name: STORE_KEY,
      storage: createIndexedDBAuthStorage("auth-store-db"),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !['login', 'logout', 'modifyUser', 'modifyProfiles', 'setLoading'].includes(key)),
        ),
      onRehydrateStorage: () => (state) => {
        if (state) {
          state.setLoading(false)
        }
      }
    }
  )
)

