import { createTheme } from '@mui/material'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import shadows, { customShadows } from './shadows'

const isLight = true

const themeOptions = {
  palette: isLight ? palette.light : palette.dark,
  typography,
  breakpoints,
  shape: { borderRadius: 8 },
  shadows: isLight ? shadows.light : shadows.dark,
  customShadows: isLight ? customShadows.light : customShadows.dark,
}

export const theme = createTheme(themeOptions)
